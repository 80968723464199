@font-face {
  font-family: 'Lato-Medium';
  src: url('fonts/Lato-Medium.woff2') format('woff2'), url('fonts/Lato-Medium.woff') format('woff'), url('fonts/Lato-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face{
  font-family: 'Lato-Semibold';
  src: url('fonts/Lato-Semibold.woff2') format('woff2'), url('fonts/Lato-Semibold.woff') format('woff'), url('fonts/Lato-Semibold.ttf') format('truetype');
  font-weight: bold;
  font-style: bold;
}
@font-face{
  font-family: 'Lato';
  src:  url('fonts/Lato-Light.woff2') format('woff2'), url('fonts/Lato-Light.woff') format('woff'), url('fonts/Lato-Light.ttf') format('truetype');
  font-weight: light;
  font-style: light;
}
@font-face{
  font-family: 'Lato-Regular';
  src: url('fonts/Lato-Regular.woff2') format('woff2'), url('fonts/Lato-Regular.woff') format('woff'), url('fonts/Lato-Regular.ttf') format('truetype');
}
@font-face{
  font-family: 'Lato-Black';
  src: url('fonts/Lato-Black.woff2') format('woff2'), url('fonts/Lato-Black.woff') format('woff'), url('fonts/Lato-Black.ttf') format('truetype');
}